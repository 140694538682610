<template>
  <span v-if="information">  
    <span v-if="information.name == undefined">
      <span v-if="information == user.information.email">
        <i class="fal fa-user"></i> {{ user.information.name }}
      </span>
      <span v-if="information != user.information.email">
        {{ information }}
      </span>
    </span>
    <span v-if="information.item_type == 'message-fax'">
      <i class="fal fa-phone"></i> {{ information.email }}
    </span>
    <span v-if="information.item_type == 'addressbook'">
      <i class="fal fa-address-book"></i> {{ information.name }} <span v-if="!short">({{ information.email }}{{ information.phone }})</span>
    </span>
    <span v-if="information.item_type == 'graph'">
      <i class="fab fa-windows"></i> {{ information.name }} <span v-if="!short">(<span v-if="information.email">{{ information.email }}</span><span v-if="information.phone">{{ information.phone }}</span>)</span>
    </span>
    <span v-if="information.item_type == 'message-link'">
      <i class="fal fa-up-right-from-square" v-if="validateEmail(information.email)"></i
      ><i class="fal fa-phone" v-if="validatePhone(information.email)"></i>
      {{ information.email }}
    </span>
    <span v-if="information.item_type == 'message-user'">
      <i class="fal fa-user"></i> {{ information.email }}
    </span>
    <span v-if="information.item_type == 'user'">
      <i class="fal fa-user"></i> {{ information.name }}<span v-if="!short">, {{ information.organisation }} ({{ information.email }})</span>
    </span>
    <span v-if="information.item_type == 'functionbox'">
      <i class="fal fa-box"></i> {{ information.name }}<span v-if="!short">,  {{ information.organisation }}({{ information.email }})</span>
    </span>
  </span>
  </template>
  <script>
  export default {
    components: {},
    props: {
      short: {
        type: Boolean,
        validator(value) {
          return value != undefined;
        },
      },
      identifier: {
        default: "",
        type: String,
      },
      item: {
        default: null,
        type: [Object, String],
      },
      message_type: {
        default: "",
        type: String,
      },
      user_uuid: {
        default: "",
        type: String,
      },    
      functionbox_uuid: {
        default: "",
        type: String,
      }
    },
    data() {
      return {
        information: null
      }
    },
    methods: { 
      async init()
      {
        if(this.item != null)
        {
          this.information = this.item;
        }else{
          if(this.identifier != "")
          {
            let exaktmatch = await this.$http.post(
                this.user.hostname + "/auto-complete/exaktmatch",
                {
                  message_type: this.message_type,
                  search: this.identifier,
                  emails: [],
                  user_uuid: this.user_uuid,
                  functionbox_uuid: this.functionbox_uuid,
                });
            if (exaktmatch.data == null) {
              this.information = this.identifier;
            } else {
              if (exaktmatch.data.item_type == null) {
                this.information = this.identifier;
              } else {
                this.information = exaktmatch.data;
              }
            }
          }
        }
      },
    },
    computed: {      
    },
    mounted() { 
      this.init();    
    },
  };
  </script>
  <style></style>
  