<template>
  <span v-if="info">{{ info.email }}<span v-if="!islast">;&nbsp;</span></span>
</template>
<script>
export default {
  props: ["index", "item", "islast"],
  data() {
    return {
      show: false,
      info: this.item,
      loading: false,
      showDialog: false,
      viewed: false,
      viewed_date: "",
      showResponse: false,
      count: 0
    };
  },
  methods: {
  },
  computed: {
  },
  mounted(){    
  }
};
</script>
<style></style>