<template>
  <span>
    <a href="#" @click="show = true"  v-b-tooltip.hover> 
      <span v-if="creator"> 
        <MessageStatus :status="info.status"></MessageStatus>
      </span>
      {{info.email}}<span v-if="!islast">;&nbsp;&nbsp;</span></a>
      
    <b-sidebar
      id="message-securelink"
      v-model="show"
      right
      no-slide
      shadow
      lazy
      backdrop
    >      
     <SecureLinkInformation :functionbox_uuid="functionbox_uuid" :response="response" :index="index" :item="info"></SecureLinkInformation>

    </b-sidebar>

  </span>
</template>
<script>
import MessageStatus from "@/components/View/MessageStatus";
import SecureLinkInformation from "@/components/View/SecureLinkInformation";
export default {
  components: { MessageStatus, SecureLinkInformation },
  props: ["response","item","index","islast","creator","status","functionbox_uuid"],
  data() {
    return {
      show: false,
      info: this.item,
      showDialog: false,
      showResponse: false,
      viewed: false,
      viewed_date: ''
    };
  },
  methods: {
    viewResponse: function() {
      this.showResponse = true;
    },
    onShow: function() {
      this.$root.$emit('bv::hide::popover')
    },
    onClose: function() {
      this.showDialog = false;
    }
  },
  computed: {    
    getStatus() {
      if(this.info.response_message_uuid != "")
      {
        return 3;
      }
      if(this.info.recalled == 1)
      {
        return 2;
      }
      if(this.info.viewed == 1)
      {
        return 1;
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return 10;
        }
      }
      return 0;
    },
    showStatus() {
      if(this.info.response_message_uuid != "")
      {
        return this.$t("RESPONSED");
      }
      if(this.info.recalled == 1)
      {
        return this.$t("recalled");
      }
      if(this.info.viewed == 1)
      {
        return this.$t("VIEWED");
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return this.$t("NOT_SENT");
        }
      }
      return this.$t("NOT_VIEWED");
    }
  }
};
</script>
<style>
</style>