<template>
  <section>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="opened" style="position: relative;">
        <b-card class="card-message">
          <UserInfo
            class="mt-2"
            image="/img/default-avatar.png"
            :primary="createdName"
            :secondary="message.creator.email"
            :optional="createdTime"
          ></UserInfo>

          <hr class="pb-0 mb-0 mt-2" />

          <div class="mt-2">
            <span v-for="(item, index) in message.attachments" :key="index">
              <Attachment
                :item="item"
                :index="index"
                :message_uuid="message_uuid"
              ></Attachment>
            </span>

            <BodyText :body="message.body"></BodyText>
          </div>

          <template v-slot:footer>
            <a @click="show = true" class="clickable"
              ><i class="ml-2 fal fa-info-circle"></i> {{ $t("DETAILS") }}</a
            >
          </template>
        </b-card>
      </div>

      <b-sidebar v-model="show" right no-slide shadow backdrop>
        <div class="px-3 py-2">
          {{ $t("MESSAGE_DETAILS") }}

          <hr class="pb-0 mb-0 mt-2 mb-3" />

          <h6 class="mb-0 no-uppercase">{{ $t("MESSAGE_UUID") }}</h6>
          {{ message.message_uuid }}

          <div v-if="message.creator">
            <h6 class="mt-3 mb-0 no-uppercase">{{ $t("CREATOR_UUID") }}</h6>
            {{ message.creator.user_uuid }}
          </div>

          <h6 class="mt-3 mb-0 no-uppercase">{{ $t("MESSAGE_VERSION") }}</h6>
          {{ message.version }}

          <h6 class="mt-3 mb-0 no-uppercase">{{ $t("TYPE") }}</h6>
          {{ $t("MESSAGES.TYPE." + message.message_type) }}

          <h6 class="mt-3 mb-0 no-uppercase">{{ $t("RESPONSE") }}</h6>
          {{ $t("MESSAGES.REQUESTED_RESPONSE") }}

          <hr class="pb-0 mb-0 mt-2" />
        </div>
      </b-sidebar>

      <div v-if="!opened && !loading" class="mt-2">
        <b-card class="card-message text-center">
          <i class="fal fa-exclamation-circle"></i>
          {{ $t(errorMessage) }}
        </b-card>
      </div>
    </b-overlay>
  </section>
</template>
<script>
import UserInfo from "@/components/Layout/UserInfo";
import Attachment from "@/components/View/Attachment";
import BodyText from "@/components/View/BodyText";
export default {
  props: ["message_uuid", "user_uuid", "functionbox_uuid"],
  components: {
    Attachment,
    UserInfo,
    BodyText,
  },
  data() {
    return {
      opened: false,
      show: false,
      loading: true,
      email: "",
      errorMessage: "",
      message: {}
    };
  },
  methods: {
    getInformation: function() {
      let self = this;
      this.$http
        .post(this.user.hostname + "/message/uuid/open", {
          message_uuid: this.message_uuid,
          user_uuid: this.user_uuid,
          functionbox_uuid: this.functionbox_uuid,
        })
        .then((response) => {
          self.message = response.data.message;
          self.opened = true;
          self.loading = false;
        })
        .catch((error) => {
          self.loading = false;
          self.opened = false;
          self.errorMessage = error.response.data;
        });
    },
  },
  computed: {
    messageContent() {
      return (
        "<html><head><link href='https://" +
        window.location.hostname +
        "/css/iframe.css' rel='stylesheet'></head><body>" +
        this.message.body +
        "</body></html>"
      );
    },
    createdTime() {
      return this.FormatDateTime(this.message.created_date);
    },
    createdName() {
      if (this.message.creator.name == "") {
        return this.message.creator.email;
      } else {
        return (
          this.message.creator.name +
          ", " +
          this.message.creator.organisation_name
        );
      }
    },
  },
  mounted: function() {
    this.getInformation();
  },
};
</script>
<style></style>
