<template>
  <span><slot></slot>    
    <b-sidebar
      sidebar-class="big_sidebar create-sefos-message"
      v-model="show"
      right
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      @shown="shownSidebar"
      no-close-on-esc
      @hidden="closeBtn"
    >    
      <b-overlay :show="loading" rounded="sm">
        <div v-if="settings"
          class="view-content create-message px-3 py-2"
        >
          <div v-if="step == 1">
            
            <div v-if="showFrom">
              <div class="info-header mt-2">{{ $t("FROM") }}</div>
              <b-form-select  name="sefos_message_select_from" class="mt-2" v-model="selected_from" :options="from_list"></b-form-select>
            </div>

            <div class="info-header mt-2">{{ $t("ADD_RECIPIENTS") }}</div>

            <SearchRecipients 
              class="w-100 mt-2"
              :user_uuid="selected_from.user_uuid"
              :functionbox_uuid="selected_from.functionbox_uuid"
              v-model="emails"
              :key="'forceRedrawRecipients_' + forceRedrawRecipientsIndex"
              @forceRedraw="forceRedrawRecipientsIndex++"></SearchRecipients>
        
            <div class="info-header mt-2">{{ $t("INFORMATION") }}</div>
            
            <b-form-input
              class="mt-2 pl-2"
              input-id="subject"
              id="subject"
              :disabled="settings.subject != ''"
              :placeholder="$t('SUBJECT')"
              :state="subject.length <= 256"
              autocomplete="off"
              v-model="subject"
            ></b-form-input>
            <div class="clearfix">
              <div class="float-left">
                <div class="error-text" v-if="subject.length > 256">
                  {{ $t('ERROR.TOO_MANY_CHARACTERS')}}
                </div>
                <b-form-text id="subject" v-if="settings.subject == ''">
                  {{ $t("SUBJECT_EMAIL") }}
                </b-form-text>               
                <b-button
                  v-if="settings.subject != '' && settings.unlock_subject"
                  @click="unlockSubject" 
                  size="sm"
                  class="no-border mt-2 mb-2"><i class="fal fa-unlock"></i> {{ $t("BTN_UNLOCK_SUBJECT") }}</b-button>                
    
              </div>
              <div class="float-right">
                <div class="text-right">
                  <small class="pull-right">{{subject.length}}/256</small>
                </div>
              </div>
            </div>
           
           
            <b-form-textarea
              class="mt-2 p-2 textarea"
              id="messageContent"
              ref="messageContent"
              :placeholder="$t('CONTENT')"
              input-id="message"
              v-model="content"
              autocomplete="off"
              rows="8"
              :state="content.length <= 20000"
              max-rows="8"
            ></b-form-textarea>     
            <div class="error-text" v-if="content.length > 20000">
              {{ $t('ERROR.TOO_MANY_CHARACTERS')}}
            </div>     
            <div class="text-right">
              <small class="pull-right">{{content.length}}/20000</small>
            </div>

            <FileSelect max-size="200" v-model="files" class="mt-2">
              <b-button size="sm" variant="secondary" class="no-border">
              <i class="fal fa-plus"></i> {{ $t("ADD_FILES") }}  ({{attachments.length + files.length}})
              </b-button>
            </FileSelect>


            <div  class="mt-2" :key="'attachments' + attachments.length">
              <div :class="filesClass">
                <span v-if="attachments.length != 0">
                  <span
                    v-for="(item, index) in attachments"
                    :key="index"
                    class="ms-fontSize-12"
                  >
                    <Attachment
                      @removeFile="removeAttachment"
                      :item="item"
                      :index="index"
                    ></Attachment>
                  </span>
                </span>
                <span v-if="files.length != 0">
                  <span
                    v-for="(item, index) in files"
                    :key="index"
                    class="ms-fontSize-12"
                  >
                    <Attachment
                      @removeFile="removeFile"
                      :item="item"
                      :index="index"
                    ></Attachment>
                  </span>
                </span>
              </div>
            </div>

            <b-form-invalid-feedback
              v-if="currentFilesize < 200 * 1024 * 1024"
            >
              {{ $t("ERROR.LARGE_FILES") }} {{ bytesToSize(200 * 1024 * 1024) }}
            </b-form-invalid-feedback>


            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

            <b-button
              :disabled="disabledGotoStep2"
              @click="gotoStep2"
              variant="primary"
              class="btn-fill"
              >{{ $t("NEXT") }}</b-button
            >
    
          </div>
          <div v-if="step == 2">
    

            <div class="info-header mt-2">{{ $t("MESSAGES.LOA_LEVELS") }}</div>

            <div class="mt-2 ml-2">
              <b-form-radio v-model="loa_level" value="3" class="mt-2" v-if="user.external_loa_levels.loa3">
                {{ loa3_text }} - LOA3 <LoaLevel class="ml-2" :loa="3"></LoaLevel>
              </b-form-radio>
              <div v-if="user.loa_level_enabled.loa2_enabled">
                <b-form-radio v-model="loa_level" value="2" class="mt-2" v-if="user.external_loa_levels.loa2">
                  {{ loa2_text }} - LOA2 <LoaLevel class="ml-2" :loa="2"></LoaLevel>
                </b-form-radio>
              </div>
              <div v-if="user.loa_level_enabled.loa1_enabled">
                <b-form-radio v-model="loa_level" value="1" class="mt-2" v-if="user.external_loa_levels.loa1">
                  {{ loa1_text }} - LOA1 <LoaLevel class="ml-2" :loa="1"></LoaLevel>
                </b-form-radio>
              </div>
            </div>

            <div v-if="settings.reply">

              <div class="info-header mt-2">{{ $t("RESPONSE") }}</div>

              <div class="ml-2">
                <b-form-checkbox
                  class="mt-2"
                  id="checkbox-1"
                  v-model="require_response"
                  name="require_response"
                  value="1"
                  switch
                  unchecked-value="0"
                >
                  {{ $t("MESSAGES.REQUEST_RESPONSE") }}
                </b-form-checkbox>
              </div>
              </div>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="goBackStep1">{{ $t("BACK") }}</b-button>

            <b-button
              @click="gotoStep3"
              variant="primary"
              class="btn-fill"
              >{{ $t("SUBMIT") }}</b-button
            >
         
          </div>
          <div v-if="step == 3">
          
            <h3>{{ $t("MESSAGES.EXTERNAL_RECIPIENTS") }}</h3>

            <p>
              {{ $t("MESSAGES.NOT_REGISTERED") }}
            </p>

            <h6>{{ $t("MESSAGES.NOT_REGISTERED_INFORMATION") }}</h6>               
            <div class="mt-2">
              <div
                v-for="(item, index) in external_participants"
                :key="index"
                class="NotRegistered"
              >
                <NotRegistered
                  @setValidated="setValidated"
                  :methods="methods"
                  :loa_level="loa_level"
                  :item="item"
                  :index="index"
                ></NotRegistered>
              </div>
            </div>

            <div>
              <h6 class="mt-4">{{ $t("MESSAGES.EXTERNAL_TEXT") }}</h6>
              <div class="mt-2 mb-2">                
                <b-form-textarea
                  class="textarea"
                  id="textarea"
                  v-model="external_text"
                  :state="external_text.length <= 1500"
                  :disabled="settings.email_body != ''"
                  :placeholder="$t('MESSAGES.EXTERNAL_TEXT_PLACEHOLDER')"
                  rows="3"
                  max-rows="3"
                ></b-form-textarea>
                <div class="clearfix">
                  <div class="float-left">
                    <div class="error-text" v-if="external_text.length > 1500">
                      {{ $t('ERROR.TOO_MANY_CHARACTERS')}}
                    </div>
                    <b-form-text id="email_body" v-if="settings.email_body == ''">
                      {{ $t("MESSAGES.EXTERNAL_TEXT_INFORMATION") }} {{ $t("MESSAGES.EXTERNAL_TEXT_INFORMATION_SMS") }}
                    </b-form-text>               
                    <b-button
                      v-if="settings.email_body != '' && settings.unlock_email_body"
                      @click="unlockEmailBody" 
                      size="sm"
                      class="no-border mt-2 mb-2"><i class="fal fa-unlock"></i> {{ $t("BTN_UNLOCK_EMAIL_BODY") }}</b-button>                
        
                  </div>
                  <div class="float-right">
                    <div class="text-right">
                      <small class="pull-right">{{external_text.length}}/1500</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="goBackStep2">{{ $t("BACK") }}</b-button>
            <b-button
              :disabled="disabledSendMessage"
              @click="startSendMessage"
              variant="primary"
              class="btn-fill"
              >{{ $t("SUBMIT") }}</b-button
            >

yyyyyyyyyyyyyyyyyyyyy
          </div>

        </div>
      </b-overlay>

    </b-sidebar>
  </span>
</template>
<script>
import * as blobUtil from "blob-util";
import SearchRecipients from "@/components/Compose/SearchRecipients_4_2";
import FileSelect from "@/components/Compose/FileSelect";
import NotRegistered from "@/components/Compose/NotRegistered";
import Attachment from "@/components/Compose/Attachment";
import LoaLevel from "@/components/Compose/LoaLevel";
export default {
  props: {
    disableLock: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    reply_message_uuid: {
      default: "",
      type: String,
    },
    reply_subject: {
      default: "",
      type: String,
    },
    reply_body: {
      default: "",
      type: String,
    },
    reply_attachments: {
      default: () => [],
      type: Array,
    },
    reply_emails: {
      default: () => [],
      type: Array,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
    reply_loa: {
      default: 0,
      type: Number,
    },
    user_uuid: {
      default: "",
      type: String,
    }
  },
  components: {
    SearchRecipients,
    NotRegistered,
    FileSelect,
    Attachment,
    LoaLevel
  },
  data() {
    return {
      settings: null,
      changedContent: false,
      forceRedrawRecipientsIndex: 0,
      attachmentIndex: 0,
      selected_from: {
        functionbox_uuid: this.functionbox_uuid,
        user_uuid: this.user_uuid
      },
      from_list: [],
      validated: [],
      step: 1,
      content: "",
      subject: "",
      require_response: 1,
      today: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      set_loa_level: 0,
      loa_level: 3,
      emails: [],
      files: [],
      methods: [],
      loading: false,
      attachments: [],
      showExternal: false,
      participants: [],
      external_text: "",
      external_participants: [],
      externalAlreadyInConversation: [],
      registered:[],
      notregistered: [],
      process: 1,
      fields: [
        {
          thStyle: "width: 100%;",
          key: "authentication_identifier",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          thStyle: "width: 45%;",
          key: "email",
          class: "hidden-mobile",
          label: this.$t("EMAIL"),
          sortable: false,
        },
        {
          thStyle: "width: 45%;",
          key: "authentication_method",
          class: "hidden-mobile",
          label: this.$t("AUTH_METHODS.VERIFY"),
          sortable: false,
        },
        {
          thStyle: "width: 10%;",
          key: "language",
          class: "hidden-mobile",
          label: this.$t("LANGUAGE.TITLE"),
          sortable: false,
        },
      ],
    };
  },
  methods: {
    unlockSubject(){
      if(this.reply_subject == "")
      {
        this.subject = this.settings.subject;
      }else{
        this.subject = this.reply_subject;
      }
      this.settings.subject = "";
      this.settings.unlock_subject = 0;
    },
    unlockEmailBody(){
      this.settings.email_body = "";
      this.settings.unlock_email_body = 0;
    },
    shownSidebar(){      
      this.getSettings();
    },
    async init() {    
      this.loading = true;
      this.emails = this.reply_emails;
      this.subject = this.reply_subject;
      if(this.settings.subject != '')
      {
        this.subject = this.settings.subject;
      }
      if(this.currentSignature != "")
      {
        this.content = "\n\n----------------------------\n" + this.currentSignature;
      }
      if(this.settings.body != '')
      {
        this.content = this.settings.body;
      }
      if(this.settings.email_body != '')
      {
        this.external_text = this.settings.email_body;
      }
      this.attachments = this.reply_attachments.slice();
      this.attachmentIndex++;
      if(this.settings.reply)
      {
        this.require_response = this.settings.reply_default;
      }else{
        this.require_response = 0;
      }
      if(this.user.loa_default_level == 0)
      {
        if(this.user.loa_level_enabled.loa1_enabled){
          this.loa_level = 1;
        }
        if(this.user.loa_level_enabled.loa2_enabled){
          this.loa_level = 2;
        }
        if(this.user.loa_level_enabled.loa3_enabled){
          this.loa_level = 3;
        }
      }else{
        this.loa_level = this.user.loa_default_level;
      }
      if(this.reply_loa != 0)
      {
        this.loa_level = this.reply_loa;
      }
      this.from_list.push({
        value: {
            functionbox_uuid: "",
            user_uuid: ""
          },
        text: this.user.information.email,
      });   
      if(this.showFrom)
      {
        if(this.user.functionboxes.items != undefined)
        {
          for (let ix = 0; ix < this.user.functionboxes.items.length; ix++) {
            let item = {
              functionbox_uuid: this.user.functionboxes.items[ix].functionbox_uuid,
              user_uuid: ""
            };
            this.from_list.push({
              value: item,
              text: this.user.functionboxes.items[ix].name + ' - ' + this.user.functionboxes.items[ix].email + "",
            });
            if(this.functionbox_uuid == this.user.functionboxes.items[ix].functionbox_uuid)
            {
              this.selected_from = {
                functionbox_uuid: this.user.functionboxes.items[ix].functionbox_uuid,
                user_uuid: ""
              };
            } 
          }
        }        
        if(this.user.delegatedboxes.items != undefined)
        {
          for (let ix = 0; ix < this.user.delegatedboxes.items.length; ix++) {
            let item = {
              functionbox_uuid: "",
              user_uuid: this.user.delegatedboxes.items[ix].user_uuid
            };
            this.from_list.push({
              value: item,
              text: this.user.delegatedboxes.items[ix].name + ' - ' + this.user.delegatedboxes.items[ix].email + "",
            });
            if(this.user_uuid == this.user.delegatedboxes.items[ix].user_uuid)
            {
              this.selected_from = {
                functionbox_uuid: "",
                user_uuid: this.user.delegatedboxes.items[ix].user_uuid
              };
            } 
          }
        }
      }      
      setTimeout(() => {
        let input = document.getElementById("messageContent");
        input.setSelectionRange(0, 0);
      }, 500); 
      await this.getMethods();
    },
    async closeBtn() {
      this.$emit('changeShow',false);
      this.attachments = [];
      this.files = [];
    },
    async goBackStep1() {      
      this.step = 1;
    },
    async getFileContent(file) {
      return new Promise((resolve) => {
        blobUtil
          .blobToBase64String(file)
          .then(function(base64String) {
            resolve(base64String);
          })
          .catch(function() {
            resolve(null);
          });
      });
    },
    async gotoStep2() {
      this.loading = true;
      for (let ix = 0; ix < this.files.length; ix++) {
        let base64Content = await this.getFileContent(this.files[ix]);
        if (base64Content == null) {
          this.$noty.error(self.$t("ERROR.EXCEPTION"));
          return false;
        } else {
          this.attachments.push({
            name: this.files[ix].name,
            type: this.files[ix].type,
            content: base64Content,
            size: this.files[ix].size
          });
        }
      }
      this.files = [];
      this.attachmentIndex++;
      this.loading = false;
      if(this.reply_loa != 0)
      {
        this.gotoStep3();
      }else{
        this.step = 2;
      }
    },
    async goBackStep2() {
      if(this.reply_loa != 0)
      {
        this.goBackStep1();
      }else{
        this.step = 2;
      }
    },
    async gotoStep3() {
      let self = this;
      this.loading = true;
      this.validated = [];
      this.showExternal = false;
      let result = await this.$http.post(
        this.user.hostname + "/message/verify/emails",
        {
          emails: this.emails,
        }
      );
      this.externalAlreadyInConversation = [];
      if(this.reply_loa != 0)
      {
        await this.$http
         .post(this.user.hostname + "/message/conversation/emails",
         {
            message_uuid: this.reply_message_uuid,
            functionbox_uuid: this.functionbox_uuid,
            user_uuid: this.user_uuid,
            emails: this.emails,
          }
        )
          .then(function(result) {
            self.externalAlreadyInConversation = result.data;
          })
          .catch(function() {
          });
      }
      this.registered = result.data.registered;
      this.notregistered = result.data.notregistered;
      this.participants = [];
      for (const email of this.registered) {
        this.participants.push({ email: email });
      }            
      this.external_participants = [];
      let default_authentication_method = "link";
      if (this.loa_level == 2) {
        default_authentication_method = "sms";
      }
      if (this.loa_level == 3) {
        default_authentication_method = "se-eid";
      }
      if (this.registered != 0 && this.notregistered.length == 0) {
        this.startSendMessage();
      }
      if(this.notregistered.length != 0)
      {
        setTimeout(function(){ 
          self.populateExternal(default_authentication_method);
        }, 500);
      }
    },
    async populateExternal(default_authentication_method)
    {
      for (let ix = 0; ix < this.notregistered.length; ix++) {
        if(this.externalAlreadyInConversation.includes(this.notregistered[ix]))
        {
          this.external_participants.push({
            email: this.notregistered[ix],
            authentication_method: "conversation",
            authentication_identifier: "",
            language: this.$i18n.locale,
          });
          this.validated.push({
            email: this.notregistered[ix],
            valid: 1,
          });
        }else{
          this.external_participants.push({
            email: this.notregistered[ix],
            authentication_method: default_authentication_method,
            authentication_identifier: "",
            language: this.$i18n.locale,
          });
          this.validated.push({
            email: this.notregistered[ix],
            valid: 1,
          });
        }
      }
      this.step = 3;
      this.loading = false;
    },
    authenticationImage(authentication_method) {
      return "/img/auth/" + authentication_method + ".png";
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.attachmentIndex++;
    },
    removeAttachment(index) {
      this.attachments.splice(index, 1);
      this.attachmentIndex++;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function(result) {
          self.methods = result.data;
          self.loading = false;
        })
        .catch(function(error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t(error.response));
        });
    },
    async verifyExternal() {
      if (this.external_participants.length == 0) {
        return true;
      }
      for (let ix = 0; ix < this.external_participants.length; ix++) {
        let external_participant = this.external_participants[ix];
        if (
          external_participant.authentication_method == "sms" &&
          external_participant.authentication_identifier == ""
        ) {
          return false;
        }
        if (
          external_participant.authentication_method == "bankid-se" &&
          external_participant.authentication_identifier == ""
        ) {
          return false;
        }
        if (
          external_participant.authentication_method == "freja" &&
          external_participant.authentication_identifier == ""
        ) {
          return false;
        }
      }
      return true;
    },
    setValidated(payload) {
      if (payload.value) {
        this.validated[payload.index].valid = 1;
      } else {
        this.validated[payload.index].valid = 0;
      }
    },
    async startSendMessage() {
      this.loading = true;
      let self = this;
      setTimeout(function(){ 
          self.sendMessage();
        }, 200);
    },
    async sendMessage() {
      this.loading = true;
      let self = this;
      if(this.external_text == "")
      {
        this.external_text = this.$t('MESSAGES.EXTERNAL_TEXT_PLACEHOLDER');
      }
      this.$http
        .post(
          this.user.hostname + "/inbox/secure",
          {
            functionbox_uuid: this.selected_from.functionbox_uuid,
            user_uuid: this.selected_from.user_uuid,
            subject: this.subject,
            body: this.content,
            attachments: this.attachments,
            participants: this.participants,
            external_text: this.external_text,
            external_participants: this.filteredParticipants,
            response_to_message_uuid: this.reply_message_uuid,
            settings: {
              require_response: parseInt(this.require_response),
              loa_level: parseInt(this.loa_level),
              open_to_date: null,
              open_from_date: null,
            },
          }
        )
        .then(function(result) {
          self.$emit('changeShow',false);
          self.attachments = [];
          self.$emit("sentMessage");
          self.loading = false;
          if(result.data.all_sent != undefined)
          {
            if(result.data.all_sent)
            {
              self.$noty.info(self.$t("SENT_MESSAGE"));
            }else{
              self.$noty.warning(self.$t("SENT_MESSAGE_NOT_SENT"));
            }
          }else{
            self.$noty.info(self.$t("SENT_MESSAGE"));
          }
        })
        .catch(function(error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH_DATA"));
        });
    },
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .get(
          this.user.hostname +
            "/sefos-message/settings/" +
            this.user.information.organisation.organisation_uuid
        )
        .then(function (result) {
          self.settings = result.data;
          self.init();
          self.loading = false;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH"));
        });
    },
  },
  computed: {    
    currentFilesize(){
      let filesize = 0;
      for (let ix = 0; ix < this.files.length; ix++) {
        filesize += this.files[ix].size;
      }
      return filesize;
    },
    currentSignature(){
      let self = this;
      let currentSignature = this.user.information.signature;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter((c) => c.functionbox_uuid == self.functionbox_uuid);          
        if (me.length != 0) {
          currentSignature = me[0].signature;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter((c) => c.user_uuid == self.user_uuid);          
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        }
      }
      return currentSignature;
    },
    contentRef(){
      if(this.reply_body != "")
      {
        return this.reply_message_uuid + "messageContent";
      }else{
        return "";
      }
    },
    loa1_text: function(){
      if(this.user.loa_level_texts == undefined)
      {
        return this.$t("LOA.1")
      }else{
         if(this.user.loa_level_texts.loa1_text != "")
        {
          return this.user.loa_level_texts.loa1_text;
        }else{
          return this.$t("LOA.1")
        }
      }
    },
    loa2_text: function(){
      if(this.user.loa_level_texts == undefined)
      {
        return this.$t("LOA.2")
      }else{
         if(this.user.loa_level_texts.loa2_text != "")
        {
          return this.user.loa_level_texts.loa2_text;
        }else{
          return this.$t("LOA.2")
        }
      }
    },
    loa3_text: function(){
      if(this.user.loa_level_texts == undefined)
      {
        return this.$t("LOA.3")
      }else{
         if(this.user.loa_level_texts.loa3_text != "")
        {
          return this.user.loa_level_texts.loa3_text;
        }else{
          return this.$t("LOA.3")
        }
      }
    },
    showFrom: function(){
      return "";
    },
    disabledGotoStep2: function() {
      if(this.currentFilesize > 200 * 1024 * 1024)
      {
        return true;
      }
      if(this.subject.length > 256)
      {
        return true;
      }
      if(this.content.length > 2000)
      {
        return true;
      }
      if (this.emails.length != 0 && this.subject != "") {
        return false;
      } else {
        return true;
      }
    },
    disabledSendMessage: function() {
      if(this.subject.length > 256)
      {
        return true;
      }
      if(this.content.length > 2000)
      {
        return true;
      }
      if(this.filteredParticipants.length == 0 && this.registered.length == 0)
      {
        return true;
      }else{
        if (this.external_participants.length != 0) {          
          if(this.external_text.length > 1500)
          {
            return true;
          }
          if (
            this.external_participants.length == this.validatedParticipants.length
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      }
    },
    filteredParticipants() {
      return this.external_participants.filter(function (item) {
        return item.authentication_method !== 'donotsend'
      });
    },
    filteredRemovedParticipants() {
      return this.external_participants.filter(function (item) {
        return item.authentication_method == 'donotsend'
      });
    },
    validatedParticipants() {
      return this.validated.filter(function(item) {
        return item.valid == 1;
      });
    },
    show_loa3() {
      if(this.user.external_loa_levels != null)
      {
        if(this.user.external_loa_levels.loa3 == 1)
        {
          return true;
        }else{
          return false;
        }
      }else{
        if(this.user.max_loa.external == 3)
        {
          return true;
        }else{
          return false;
        }
      }
    },
    show_loa2() {
      if(this.user.external_loa_levels != null)
      {
        if(this.user.external_loa_levels.loa2 == 1)
        {
          return true;
        }else{
          return false;
        }
      }else{
        if(this.user.max_loa.external >= 2)
        {
          return true;
        }else{
          return false;
        }
      }
    },
    filesClass(){
      if(this.currentFilesize < (1048576*200))
      {
        return ["is-valid"];
      }else{
        return ["is-invalid"];
      }
    }
  },
  mounted() {    
  },
};
</script>
