<template>
  <span><slot></slot>
    <b-sidebar
      sidebar-class="big_sidebar create-sefos-message"
      v-model="show"
      right
      backdrop
      no-slide
      lazy
      shadow
      no-header-close
      no-close-on-backdrop
      @shown="shownSidebar"
      no-close-on-esc
      @hidden="closeBtn"
    >    
      <b-overlay :show="loading" rounded="sm">
        <div
          class="view-content create-message px-3 py-2"
          v-if="settings"
        >

        <div class="info-header mt-2">{{ $t("TO") }}</div>

        <SearchRecipients disable-search class="w-100 mt-2"
        :functionbox_uuid="functionbox_uuid"
        :user_uuid="user_uuid" v-model="emails"></SearchRecipients>
   
            <div class="info-header mt-2">{{ $t("INFORMATION") }}</div>

           
            <b-form-input
            class="mt-2 pl-2"
            input-id="subject"
            id="subject"
            :disabled="settings.subject != ''"
            :placeholder="$t('SUBJECT')"
            :state="subject.length <= 256"
            autocomplete="off"
            v-model="subject"
          ></b-form-input>
          <div class="clearfix">
            <div class="float-left">
              <div class="error-text" v-if="subject.length > 256">
                {{ $t('ERROR.TOO_MANY_CHARACTERS')}}
              </div>
              <b-form-text id="subject" v-if="settings.subject == ''">
                {{ $t("SUBJECT_EMAIL") }}
              </b-form-text>               
              <b-button
                v-if="settings.subject != '' && settings.unlock_subject"
                @click="unlockSubject" 
                size="sm"
                class="no-border mt-2 mb-2"><i class="fal fa-unlock"></i> {{ $t("BTN_UNLOCK_SUBJECT") }}</b-button>                
  
            </div>
            <div class="float-right">
              <div class="text-right">
                <small class="pull-right">{{subject.length}}/256</small>
              </div>
            </div>
          </div>

            <b-form-textarea
              class="mt-2 textarea"
              id="messageContent"
              input-id="message"
              ref="messageContent"
              v-model="content"
              autocomplete="off"
              :placeholder="$t('CONTENT')"
              rows="10"
              :state="content.length < 20000"
              max-rows="10"
            ></b-form-textarea>
            <b-form-invalid-feedback :state="content.length < 20000">
              {{ $t('ERROR.TOO_MANY_CHARACTERS')}}
            </b-form-invalid-feedback>
            <div class="text-right">
              <small class="pull-right">{{content.length}}/20000</small>
            </div>

            <form class="mt-2">
              <FileSelect v-model="files" class="mt-2">
                <b-button size="sm" variant="secondary" class="no-border">
                  <i class="fal fa-plus"></i> {{ $t("ADD_FILES") }}  ({{files.length}})
                </b-button>
              </FileSelect>
            </form>

            <div  class="mt-2">
              <span v-if="reply_attachments.length != 0">
              <span
                v-for="(item, index) in reply_attachments"
                :key="index"
                class="ms-fontSize-12"
              >
                <Attachment
                  @removeFile="removeFile"
                  :item="item"
                  :index="index"
                ></Attachment>
              </span>
            </span>
            <span v-if="files.length != 0">
              <span
                v-for="(item, index) in files"
                :key="index"
                class="ms-fontSize-12"
              >
                <Attachment
                  @removeFile="removeFile"
                  :item="item"
                  :index="index"
                ></Attachment>
              </span>
            </span>
          </div>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="closeBtn">{{ $t("CLOSE") }}</b-button>

            <b-button
              :disabled="disableSend"
              @click="sendReply"
              variant="primary"
              class="btn-fill"
              >{{ $t("SUBMIT") }}</b-button
            >
    yyyyyyyyyyyyyyyyyyyy

        </div>
      </b-overlay>

    </b-sidebar>
  </span>
</template>
<script>
import * as blobUtil from "blob-util";
import SearchRecipients from "@/components/Compose/SearchRecipients_4_2";
import FileSelect from "@/components/Compose/FileSelect";
import Attachment from "@/components/Compose/Attachment";
export default {
  props: {
    reply_message_uuid: {
      default: "",
      type: String,
    },
    reply_subject: {
      default: "",
      type: String,
    },
    reply_body: {
      default: "",
      type: String,
    },
    reply_attachments: {
      default: () => [],
      type: Array,
    },
    reply_emails: {
      default: () => [],
      type: Array,
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    show: {
      default: false,
      type: Boolean,
    },
    user_uuid: {
      default: "",
      type: String,
    }
  },
  components: {
    SearchRecipients,
    FileSelect,
    Attachment
  },
  data() {
    return {
      settings: null,
      content: "",
      subject: this.reply_subject,
      today: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      emails: [],
      files: [],
      methods: [],
      loading: false,
      attachments: [],
      showExternal: false,
      participants: [],
      external_text: "",
      external_participants: [],
      process: 1,
      fields: [
        {
          thStyle: "width: 100%;",
          key: "authentication_identifier",
          class: "hidden-not-mobile",
          thClass: "hidden",
        },
        {
          thStyle: "width: 45%;",
          key: "email",
          class: "hidden-mobile",
          label: this.$t("EMAIL"),
          sortable: false,
        },
        {
          thStyle: "width: 45%;",
          key: "authentication_method",
          class: "hidden-mobile",
          label: this.$t("AUTH_METHODS.VERIFY"),
          sortable: false,
        },
        {
          thStyle: "width: 10%;",
          key: "language",
          class: "hidden-mobile",
          label: this.$t("LANGUAGE.TITLE"),
          sortable: false,
        },
      ],
    };
  },
  methods: {
    unlockSubject() {
      if (this.reply_subject == "") {
        this.subject = this.settings.subject;
      } else {
        this.subject = this.reply_subject;
      }
      this.settings.subject = "";
      this.settings.unlock_subject = 0;
    },
    unlockEmailBody() {
      this.settings.email_body = "";
      this.settings.unlock_email_body = 0;
    },
    shownSidebar() {
      this.getSettings();
    },
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .get(
          this.user.hostname +
            "/sefos-message/settings/" +
            this.user.information.organisation.organisation_uuid
        )
        .then(function (result) {
          self.settings = result.data;
          self.init();
          self.loading = false;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH"));
        });
    },
    async init() {      
      this.emails = this.reply_emails;
      this.subject = this.reply_subject;
      if (this.settings.subject != "") {
        this.subject = this.settings.subject;
      }
      if (this.settings.email_body != "") {
        this.external_text = this.settings.email_body;
      }
      if(this.reply_body != "")
      {
        this.content = this.reply_body;
      }else{

        if(this.currentSignature != "")
        {
          this.content = "\n\n----------------------------\n" + this.currentSignature;
        }
      }
      this.loading = false;
    },
    async closeBtn() {
      this.$emit('changeShow',false);
      this.$emit("sentMessage");
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async getFileContent(file) {
      return new Promise((resolve) => {
        blobUtil
          .blobToBase64String(file)
          .then(function(base64String) {
            resolve(base64String);
          })
          .catch(function() {
            resolve(null);
          });
      });
    },
    async sendReply() {
        this.loading = true;
        this.attachments = [];
        for (let ix = 0; ix < this.files.length; ix++) {
          let base64Content = await this.getFileContent(this.files[ix]);
          if (base64Content == null) {
            this.$noty.error(self.$t("ERROR.EXCEPTION"));
            return false;
          } else {
            this.attachments.push({
              name: this.files[ix].name,
              type: this.files[ix].type,
              content: base64Content,
            });
          }
        }
        let self = this;
        this.$http
          .post(
            this.user.hostname + "/inbox/secure",
            {
              functionbox_uuid: this.functionbox_uuid,
              user_uuid: this.user_uuid,
              subject: this.subject,
              body: this.content,
              attachments: this.attachments,
              participants: [],
              external_text: "",
              external_participants: [],
              response_to_message_uuid: this.reply_message_uuid,
              settings: {
                require_response: 1,
                loa_level: 1,
                open_to_date: null,
                open_from_date: null,
              },
            }
          )
          .then(function(result) {
            self.$emit('changeShow',false);
            self.attachments = [];
            self.$emit("sentMessage");
            self.loading = false;
            if(result.data.all_sent != undefined)
            {
              if(result.data.all_sent)
              {
                self.$noty.info(self.$t("SENT_MESSAGE"));
              }else{
                self.$noty.warning(self.$t("SENT_MESSAGE_NOT_SENT"));
              }
            }else{
              self.$noty.info(self.$t("SENT_MESSAGE"));
            }
          })
          .catch(function(error) {
            self.loading = false;
            if(error.toString() == "Error: fail_4_4") return;
            self.$noty.error(self.$t("ERROR.COULD_NOT_FETCH_DATA"));
          });
    }
  },
  computed: {
    currentSignature(){
      if(this.checkValidVersion("4.0.0"))
      {
        let self = this;
        let currentSignature = this.user.information.signature;
        if (this.functionbox_uuid != "") {
          let me = this.user.functionboxes.items.filter((c) => c.functionbox_uuid == self.functionbox_uuid);          
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        } else {
          if (this.user_uuid != "") {
            let me = this.user.delegatedboxes.items.filter((c) => c.user_uuid == self.user_uuid);          
            if (me.length != 0) {
              currentSignature = me[0].signature;
            }
          }
        }
        return currentSignature;
      }else{
        return "";
      }
    },
    contentRef(){
      if(this.reply_body != "")
      {
        return this.reply_message_uuid + "messageContent";
      }else{
        return "";
      }
    },
    disableSend: function() {
      if (this.emails.length != 0 && this.subject != "") {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
  },
};
</script>