<template>
  <span v-if="info">
    <a href="#" @click="show = true">   
      {{ info.name }}
      <MessageStatus :status="info.status"></MessageStatus>
      {{ info.email }}<span v-if="!islast">;&nbsp;&nbsp;</span></a
    >
    <b-sidebar
      id="message-participant"
      v-model="show"
      right
      no-slide
      shadow
      backdrop
    >
      <div class="px-3 py-2">
        <b-overlay :show="loading" rounded="sm">
          <UserInfo
            class="mt-4"
            image="/img/default-avatar.png"
            :primary="info.email"
          ></UserInfo>

          <hr class="pb-0 mb-0 mt-2" />

          <dl class="clearfix mt-2">
            <dt>{{ $t("STATUS") }}</dt>
            <dd>  
              <MessageStatus :status="info.status" show-text></MessageStatus>
            </dd>
            <dt v-if="info.fetched">{{ $t("VIEWED") }}</dt>
            <dd v-if="info.fetched">
              {{
                FormatDateTime(info.fetched_date)
              }}
            </dd>
            <dt v-if="info.recalled">{{ $t("recalled") }}</dt>
            <dd v-if="info.recalled">
              {{
                FormatDateTime(info.recalled_date)
              }}
            </dd>
            <dt v-if="responsed">{{ $t("RESPONSED") }}</dt>
            <dd v-if="responsed">
              {{
                FormatDateTime(info.response_date)
              }}
            </dd>
          </dl>

          <hr class="pb-0 mb-0 mt-2" />

          <b-button
            v-if="count != 0"
            variant="primary"
            size="sm"
            class="btn-fill mt-2"
          :to="{
                name: 'Conversation',
                query: {
                  conversation_uuid: this.item.conversation_uuid,
                  user_uuid: this.user_uuid,
                  functionbox_uuid: this.functionbox_uuid,
                  message_uuid: this.message_uuid,
                },
              }"
            >{{ $t("SHOW_CONVERSATION") }}</b-button
          >

          <b-button
            v-if="getStatus == 0"
            variant="danger"
            size="sm"
            class="btn-fill mt-2"
            @click="recall"
            >{{ $t("RECALL") }}</b-button
          >
          <b-button
            v-if="getStatus == 10"
            variant="primary"
            size="sm"
            class="btn-fill mt-2"
            @click="resend"
            >{{ $t("RESEND") }}</b-button
          >
        </b-overlay>
      </div>
    </b-sidebar>
  </span>
</template>
<script>
import MessageStatus from "@/components/View/MessageStatus";
import UserInfo from "@/components/Layout/UserInfo";
export default {
  components: { UserInfo, MessageStatus  },
  props: ["message_uuid","response", "index", "item", "islast", "creator", "status", "user_uuid", "functionbox_uuid"],
  data() {
    return {
      show: false,
      info: this.item,
      loading: false,
      showDialog: false,
      viewed: false,
      viewed_date: "",
      showResponse: false,
      count: 0
    };
  },
  methods: {
    viewResponse: function() {
      this.showResponse = true;
    },
    onShow: function() {
      this.$root.$emit("bv::hide::popover");
    },
    onClose: function() {
      this.showDialog = false;
    },
    recall: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/user/recall",
              {
                participant_id: self.info.id,
              }
            )
            .then(function(response) {
              self.loading = false;
              self.info.recalled = 1;
              self.info.recalled_date = response.data.recalled_date;
              self.$noty.info(self.$t("UPDATED"));
            })
            .catch(function(error) {
              self.loading = false;
              if(error.toString() == "Error: fail_4_4") return;
              if (error.response != undefined) {
                self.$noty.error(self.$t(error.response.data));
              }
            });
        }
      })
      .catch(function(){
      }); 
    },
    resend: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/resend",
              {
                participant_id: self.info.id,
              }
            )
            .then(function() {
              self.loading = false;
              if(self.info.sent != undefined)
              {
                self.info.sent = 1;
              }
              self.$noty.info(self.$t("UPDATED"));
            })
            .catch(function(error) {
              self.loading = false;
              if(error.toString() == "Error: fail_4_4") return;
              if (error.response != undefined) {
                self.$noty.error(self.$t(error.response.data));
              }
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: {
    responsed() {
      return this.count != 0;
    },
    getStatus() {
      if (this.responsed) {
        return 3;
      }
      if (this.info.recalled == 1) {
        return 2;
      }
      if (this.info.fetched == 1) {
        return 1;
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return 10;
        }
      }
      return 0;
    },
    popoverTarget() {
      return "Participant" + this.index;
    },
    showStatus() {
      if (this.responsed) {
        return this.$t("RESPONSED");
      }
      if (this.info.recalled == 1) {
        return this.$t("RECALLED");
      }
      if (this.info.fetched == 1) {
        return this.$t("VIEWED");
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return this.$t("NOT_SENT");
        }
      }
      return this.$t("NOT_VIEWED");
    },
  },
  mounted(){
  }
};
</script>
<style></style>
