<template>
  <span v-if="message" :key="showKey">
    <span v-if="loaded">
    <span v-if="showComponent">
      <span v-if="!showMultiple">
        <b-button class="no-border" size="sm" @click="showSefos">
          <slot></slot>
        </b-button>
      </span>
      <span v-if="showMultiple">
        <b-dropdown class="" size="sm" toggle-class="no-border">
          <template #button-content><slot></slot></template>
          <b-dropdown-item href="#" @click="showSefos"
            ><img class="icon mr-2" src="/img/message_type/message.png" />
            {{ $t("MESSAGES.TYPE.message") }}</b-dropdown-item
          ></b-dropdown
        >
      </span>

      <AddSefosMessage v-if="checkValidVersion('4.3.0')"
        :show="showSefosMessage"
        :user_uuid="user_uuid"
        :reply_loa="reply_loa"
        :reply_attachments="reply_attachments"
        :reply_subject="reply_subject"
        :reply_body="reply_body"
        :reply_emails="reply_emails"
        :reply_message_uuid="message.message_uuid"
        :disable-lock="true"
        @changeShow="changeShowSefosMessage"
        @sentMessage="sentMessage"
        :functionbox_uuid="functionbox_uuid"
      ></AddSefosMessage>
      <AddSefosMessage_4_2 v-if="!checkValidVersion('4.3.0')"
        :show="showSefosMessage"
        :user_uuid="user_uuid"
        :reply_loa="reply_loa"
        :reply_attachments="reply_attachments"
        :reply_subject="reply_subject"
        :reply_body="reply_body"
        :reply_emails="reply_emails"
        :reply_message_uuid="reply_message_uuid"
        :disable-lock="true"
        @changeShow="changeShowSefosMessage"
        @sentMessage="sentMessage"
        :functionbox_uuid="functionbox_uuid"
      ></AddSefosMessage_4_2>
    </span></span>
  </span>
</template>
<script>
import AddSefosMessage from "@/components/Compose/Message/SefosMessage";
import AddSefosMessage_4_2 from "@/components/Compose/Message/SefosMessage_4_2";
export default {
  props: {
    replyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    message: {
      default: null,
      type: Object,
    },
  },
  components: {
    AddSefosMessage, AddSefosMessage_4_2
  },
  data() {
    return {
      functionbox_uuid: "",
      user_uuid: "",
      showIndex: 0,
      showComponent: true,
      showSefosMessage: false,
      showSdkMessage: false,
      showFaxMessage: false,
      reply_identifier: "",
      reply_message_uuid: "",
      reply_subject: "",
      reply_body: "",
      reply_emails: [],
      reply_attachments: [],
    };
  },
  methods: {
    getEmails(recipients) {
      let emails = "";
      for (let ix = 0; ix < recipients.length; ix++) {
        let item = this.message.recipients[ix];
        if (item.email != this.currentEmail) {
          if (item.type == "SEFOS" || item.type == "LINK") {
            if (emails == "") {
              emails = item.email;
            } else {
              emails = ", " + item.email;
            }
          }
        }
      }
      return emails;
    },
    buildReplyContent() {
      let to = this.getEmails(this.message.recipients);
      let sent = this.FormatDateTime(this.message.created_date);
      let content =
        "From: " +
        this.message.creator.name +
        " - " +
        this.message.creator.email;
      content = content + "\nSent: " + sent;
      content = content + "\nTo: " + to;
      content = content + "\nSubject: " + this.message.subject;
      content = content + "\n\n" + this.message.body;
      return content;
    },
    changeShowSefosMessage(val) {
      this.showIndex++;
      this.showSefosMessage = val;
    },
    changeShowSdkMessage(val) {
      this.showIndex++;
      this.showSdkMessage = val;
    },
    changeShowFaxMessage(val) {
      this.showIndex++;
      this.showFaxMessage = val;
    },
    showSefos() {
      this.showSefosMessage = true;
    },
    showFax() {
      this.showFaxMessage = true;
    },
    showSDK() {
      this.showSdkMessage = true;
    },
    sentMessage() {
      this.showIndex++;
      this.showSefosMessage = false;
      this.showSdkMessage = false;
      this.showFaxMessage = false;
      this.$emit("sentMessage");
    },
  },
  computed: {
    showKey() {
      return "showKey" + this.showIndex;
    },
    showMultiple() {
      if (this.functionbox_uuid == "") {
        return false;
      } else {
        return this.sdkVisible == true || this.faxVisible == true;
      }
    },
    composeText() {
      return this.$t("MESSAGES.COMPOSE") + " - SEFOS";
    },
    faxVisible() {
      return false;
    },
    sdkVisible() {
      return false;
    },
    loaded() {
      return this.reply_subject != "" && this.message;
    },
    regular_message() {
      if (
        this.message.message_type == "message" ||
        this.message.message_type == "response" ||
        this.message.message_type == "creator-response" ||
        this.message.message_type == "link-response" ||
        this.message.message_type == "creator-link-response"
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentEmail() {
      let self = this;
      let currentEmail = this.user.information.email;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter(
          (c) => c.functionbox_uuid == self.functionbox_uuid
        );
        if (me.length != 0) {
          currentEmail = me[0].email;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter(
            (c) => c.user_uuid == self.user_uuid
          );
          if (me.length != 0) {
            currentEmail = me[0].email;
          }
        }
      }
      return currentEmail;
    },
    currentSignature() {
      let self = this;
      let currentSignature = this.user.information.signature;
      if (this.functionbox_uuid != "") {
        let me = this.user.functionboxes.items.filter(
          (c) => c.functionbox_uuid == self.functionbox_uuid
        );
        if (me.length != 0) {
          currentSignature = me[0].signature;
        }
      } else {
        if (this.user_uuid != "") {
          let me = this.user.delegatedboxes.items.filter(
            (c) => c.user_uuid == self.user_uuid
          );
          if (me.length != 0) {
            currentSignature = me[0].signature;
          }
        }
      }
      return currentSignature;
    },
  },
  mounted() {
    this.reply_loa = this.message.settings.loa_level;
    if (this.message.response_to_message == "") {
      this.reply_subject =
        this.$t("FORWARD_START") + " " + this.message.subject;
    } else {
      this.reply_subject = this.message.subject;
    }
    if (this.currentSignature != "") {
      this.reply_body =
        "\n\n----------------------------\n" +
        this.currentSignature +
        "\n\n---------------------------------------------------------\n" +
        this.buildReplyContent();
    } else {
      this.reply_body =
        "\n\n---------------------------------------------------------\n" +
        this.buildReplyContent();
    }
    this.reply_message_uuid = this.message.message_uuid;
    if (this.link_message) {
      this.showComponent = false;
    }
    this.reply_emails = [];
    this.reply_attachments = this.message.attachments;
  },
};
</script>
